export interface BreadcrumbLink {
  label: string
  to: string
}

export type BreadcrumbLinks = BreadcrumbLink[]

export interface Breadcrumbs {
  currentPage: string
  links: BreadcrumbLinks
}

export function getBreadcrumbsItems(
  currentPage: string,
  links?: BreadcrumbLinks
): Breadcrumbs {
  return {
    currentPage,
    links: links || []
  }
}
