<script lang="ts" setup>
import { Breadcrumbs } from "~/utils/breadcrumbs/breadcrumbs"
import { WrapperImageProps } from "../WrapperImage.props"

const props =
  defineProps<{
    breadcrumbsLinks?: Breadcrumbs
    wrapperImage?: WrapperImageProps
  }>()
</script>

<template>
  <UtilsBreadcrumbs
    v-if="props.breadcrumbsLinks"
    v-bind="props.breadcrumbsLinks"
  />
  <div class="relative flex justify-center py-6 sm:py-12">
    <UtilsWrapperImage
      v-if="wrapperImage || bgLoginImage"
      v-bind="wrapperImage || bgLoginImage"
      class="
        wrapper-image-full-container-centered
        absolute
        inset-0
        -z-50
        hidden
        md:block
      "
    />
    <div
      class="
        padded
        flex
        w-full
        flex-col
        justify-center
        gap-y-4
        sm:w-[392px]
        md:px-0
        lg:w-[424px]
      "
    >
      <slot></slot>
    </div>
  </div>
</template>
